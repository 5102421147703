import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const LoadingSpinner = () => {
	return (
		<div className="flex justify-center items-center w-full h-screen">
			<ThreeDots
				height="80"
				width="80"
				radius="9"
				ariaLabel="three-dots-loading"
				visible={true}
				color="#704998"
			/>
		</div>
	);
};

export default LoadingSpinner;
