import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import { Company, Contact, IndustryContent } from '../components';

import { FaRegCommentDots } from 'react-icons/fa6';
import { TbMessage2Question } from 'react-icons/tb';

//services
import {
	getIndustries,
	getIndustry,
} from '../services/industries/industries.services';
import LoadingSpinner from '../components/LoadingSpinner';
import { getSiteSettings } from '../services/home/home.services';

import { Tab } from '@headlessui/react';
import { AiOutlineRight } from 'react-icons/ai';

import { getFileUrl } from '../utils/imageURL';
import customHtmlParser from '../utils/customHtmlParser';

const DEFAULT_PAGE_SIZE = 4;

const IndustryDetail = () => {
	const [selectedIndex, setSelectedIndex] = useState(0);
	const { slug } = useParams();

	const navigate = useNavigate();

	const navigateToContact = () => {
		window.scrollTo(0, 0);
		navigate('/contact');
	};

	const navigateToIndustry = (slug) => {
		navigate(`/industries/${slug}`);
	};

	// Queries
	const { data: industries, isPending: industriesLoading } = useQuery({
		queryKey: ['industries'],
		queryFn: () => getIndustries(0, DEFAULT_PAGE_SIZE),
	});
	const { data: siteSettings, isPending: siteSettingsLoading } = useQuery({
		queryKey: ['site-settings'],
		queryFn: () => getSiteSettings(),
	});
	const { data: industry, isPending: industryLoading } = useQuery(
		{
			queryKey: ['industry', slug],
			queryFn: () => getIndustry(slug, 1),
		}
		// { enabled: !!id }
	);

	useEffect(() => {
		if (industries && slug) {
			const activeIndex = industries.findIndex((ind) => ind.slug === slug);
			if (activeIndex !== -1) {
				setSelectedIndex(activeIndex);
			}
		}
	}, [slug, industries]);

	if (industriesLoading || siteSettingsLoading || industryLoading) {
		return <LoadingSpinner />;
	}

	console.log('industry', industry);

	return (
		<>
			<Helmet>
				<title>{`PSCUBE | ${industry?.title}`}</title>
			</Helmet>
			{/* Hero Section */}
			<div className="bg-white-400">
				<div className="max-container padding-x">
					<IndustryContent industry={industry} />
				</div>
			</div>
			{/* Main content */}
			<div className="flex gap-16 max-container padding-x pt-16">
				<div className="hidden md:block md:w-4/12">
					<h4 className="text-2xl font-semibold">Other Sectors</h4>
					<ul className="pt-8">
						<Tab.Group
							vertical
							selectedIndex={selectedIndex}
							onChange={setSelectedIndex}
						>
							<Tab.List>
								{industries?.map((industry) => {
									const { id, slug, title } = industry;
									return (
										<Tab
											key={id}
											className="p-0 flex w-full outline-none border-none"
											onClick={() => {
												window.scrollTo(0, 0);
												navigateToIndustry(slug);
												// setSelectedTab(id);
											}}
										>
											{({ selected }) => (
												<span
													className={`w-full flex items-center gap-3 text-left text-lg cursor-pointer font-medium border-b border-white-300 pl-2 py-4 px-0 ${
														selected ? ' text-purple-500' : 'text-black'
													}`}
												>
													{selected && <AiOutlineRight />}
													{title}
												</span>
											)}
										</Tab>
									);
								})}
							</Tab.List>
						</Tab.Group>
					</ul>
					{/* <div className="my-12 bg-purple-100 rounded-xl">
						<div className="portfolio-card">
							<h3 className="text-3xl text-purple-500 font-semibold">
								View Portfolio
							</h3>
							<p className="text-purple-500 leading-6 pt-4 pb-8">
								This is the first benefit of IT sector investment.
							</p>
							<button className="button-transition button-purple-hover bg-purple-500 text-white-400 rounded-xl font-semibold leading-[18px]">
								Download Now
							</button>
						</div>
					</div> */}
					<div className="contact-card">
						<span className="w-[56px] h-[56px]">
							<FaRegCommentDots style={{ width: '56px', height: '56px' }} />
						</span>
						<h3 className="text-3xl font-semibold py-4">Let's Talk</h3>
						<p className="leading-6">{siteSettings?.phone}</p>
						<p className="pt-1 pb-8">{siteSettings?.email2}</p>
						<button
							className="button-transition hover:bg-[#e3e3e5] hover:border-[#e3e3e5] bg-white-400 text-purple-500 rounded-xl font-bold leading-[18px]"
							onClick={navigateToContact}
						>
							Contact Us
						</button>
					</div>
				</div>
				<div className="w-full md:w-8/12">
					<h3 className="title">Why Choose PS Cube for {industry?.title}?</h3>
					<div className="w-full pt-8">
						<img
							src={getFileUrl(industry?.image)}
							className="rounded-xl w-full h-[424px] object-cover"
							alt="desktop"
						/>
					</div>
					<div className="sub-title py-8">
						{industry?.description
							? customHtmlParser(industry?.description)
							: ''}
					</div>

					<div className="flex justify-end gap-2 mt-8">
						<span className="w-[40px] h-[40px]">
							<TbMessage2Question
								style={{ height: '40px', width: '40px', color: '#606060' }}
							/>
						</span>
						<span className="cursor-pointer">
							<p className="text-black-300 leading-6">More question?</p>
							<p className="text-purple-500 leading-6 font-semibold">
								Get a support Now
							</p>
						</span>
					</div>
				</div>
			</div>

			<Company />
			<Contact />
		</>
	);
};

export default IndustryDetail;
