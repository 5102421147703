import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser';

// services
import { getCMS, getCMSPageTitle } from '../services/home/home.services';

import LoadingSpinner from '../components/LoadingSpinner';

const DEFAULT_PAGE_SIZE = 4;
const policy_slug = 'policy';

const Policy = () => {
	// Queries
	const { data: policyCMS, isPending: policyCMSLoading } = useQuery({
		queryKey: ['policy-cms', policy_slug],
		queryFn: () => getCMS(policy_slug, DEFAULT_PAGE_SIZE),
	});

	const { data: pageTitle, isPending: pageTitleLoading } = useQuery({
		queryKey: ['cms-pageTitle', policy_slug],
		queryFn: () => getCMSPageTitle(policy_slug, DEFAULT_PAGE_SIZE),
	});

	if (policyCMSLoading || pageTitleLoading) {
		return <LoadingSpinner />;
	}

	const parsedDesc = parse(policyCMS?.[0]?.description);
	return (
		<>
			<Helmet>
				<title>{`PSCUBE | ${pageTitle?.title}`} </title>
			</Helmet>
			<div className="bg-white-400">
				<div className="max-container padding-x pt-[72px] pb-10 text-center">
					<h3 className="title">{pageTitle?.title}</h3>
				</div>
			</div>
			<div className="flex flex-col max-container padding-x pt-[64px] md: py-[128px]">
				{parsedDesc}
			</div>
		</>
	);
};

export default Policy;
