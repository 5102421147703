import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { useInView } from 'react-intersection-observer';

import { Button, Contact } from '../components';
import LoadingSpinner from '../components/LoadingSpinner';
import { rightTransitionVariants } from '../components/transitions/RightTransitionVariants';
import { leftTransitionVariants } from '../components/transitions/LeftTransitionVariants';

// utils
import { getFileUrl } from '../utils/imageURL';
import { removeHtml } from '../utils/removeHtml';

// services
import { getIndustries } from '../services/industries/industries.services';
import { getCMSPageTitle } from '../services/home/home.services';

const DEFAULT_PAGE_SIZE = 4;
const industry_slug = 'industries-page';

const Industries = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const controls = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			controls.start('visible');
		}
	}, [controls, inView]);

	const getButtonLabel = () => {
		if (location.pathname === '/industries') {
			return 'Know More';
		} else if (location.pathname.startsWith('/industries/')) {
			return 'Get Started';
		}
	};

	const navigateToIndustry = (slug) => {
		// window.scrollTo(0, 0);
		navigate(`/industries/${slug}`);
	};

	// Queries
	const { data: industries, isPending: industriesLoading } = useQuery({
		queryKey: ['industries'],
		queryFn: () => getIndustries(0, DEFAULT_PAGE_SIZE),
	});
	const { data: pageTitle, isPending: pageTitleLoading } = useQuery({
		queryKey: ['cms-pageTitle', industry_slug],
		queryFn: () => getCMSPageTitle(industry_slug, DEFAULT_PAGE_SIZE),
	});

	if (industriesLoading || pageTitleLoading) {
		return <LoadingSpinner />;
	}

	console.log('pageTitle', industries);

	return (
		<>
			<Helmet>
				<title>{`PSCUBE | ${pageTitle?.title}`}</title>
			</Helmet>
			<section
				className="max-container padding-x"
				style={{ overflow: 'hidden' }}
			>
				{industries?.map((industry, index) => {
					const { id, slug, title, shortDescription, image } = industry;

					const isSecondItem = index === 1;
					return (
						<div
							key={id}
							className={`flex flex-col-reverse justify-center items-center gap-10 padding-y animate
							md:flex-row md:justify-between md:gap-0 
							${isSecondItem ? 'md:flex-row-reverse' : ''}`}
						>
							{/* 
							<motion.div
								ref={ref}
								animate={controls}
								initial="hidden"
								variants={
									isSecondItem
										? rightTransitionVariants
										: leftTransitionVariants
								}
								// whileInView={{
								// 	x: isSecondItem ? [160, 0] : [-160, 0],
								// 	opacity: [0, 1],
								// }}
								transition={{ duration: 1, ease: 'easeInOut' }}
								className="w-full md:w-2/4 lg:w-2/4 xl:w-2/5"
							>
							*/}
							<div className="w-full md:w-2/4 lg:w-2/4 xl:w-2/5">
								<h3 className="heading-text">{title}</h3>
								<p className="sub-title pt-4 pb-10">
									{removeHtml(shortDescription)}
								</p>
								<Button
									label={getButtonLabel()}
									onClick={() => {
										navigateToIndustry(slug);
									}}
								/>
							</div>
							<div className="w-full md:w-2/4">
								<img src={getFileUrl(image)} alt="it industry" />
							</div>
							{/* <motion.div
								variant={scaleVariants}
								whileInView={scaleVariants.whileInView}
								className="w-full md:w-2/4"
							>
								<motion.img src={getFileUrl(icon)} alt="it industry" />
							</motion.div> */}
						</div>
					);
				})}
			</section>
			<Contact />
		</>
	);
};

export default Industries;
