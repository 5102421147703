import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';
import { removeHtml } from '../utils/removeHtml';

const Stats = ({ homeCMS }) => {
    const stats = homeCMS?.slice(2, 6);

    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                // Set isVisible to true when the section enters the viewport
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 } // Adjust threshold as needed
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        // Cleanup observer when component unmounts
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);



    const bounceVariants = {
        rest: {
            scale: 0.9,
            transition: {
                // duration: 0.3,
                // ease: 'easeInOut',
            },
        },
        hover: {
            scale: 1.3,
            transition: {
                // duration: 0.5,
                ease: 'easeInOut',
            },
        },
    };

    return (
        <div className="max-container padding-x pt-[64px] md:pt-[104px] pb-[90px] sm:py-[64px]">
            <div className="flex flex-col items-center text-center">
                <p className="top-title">Key Stats</p>
                <h3 className="title">{homeCMS?.[1]?.title}</h3>
                <p className="sub-title w-full lg:max-w-lg">
                    {removeHtml(homeCMS?.[1]?.description)}
                </p>
            </div>
            <div className="flex flex-wrap justify-between sm:justify-center max-sm:gap-6 gap-8 xl:gap-16 mt-16 max-sm:px-5">
                {stats?.map((stat) => (

                    <div
                        key={stat?.id}

                        className="flex flex-col sm:text-center md:border-r-2 max-sm:min-w-[0%] sm:pr-8 xl:pr-16 last:border-none cursor-pointer"

                    >

                        <motion.div
                            className="text-[26px] leading-9 sm:text-4xl font-bold text-purple-500 mb-2"
                            // variants={bounceVariants}
                            // whileHover="hover"
                            // whileTap="rest"
                        >
                            <div ref={ref}>
                                {isVisible && (stat?.title && stat.title.includes('%') ? (
                                    <CountUp
                                        start={0}
                                        end={parseInt(stat?.title)}
                                        duration={5}
                                        formattingFn={(value) => `${value}%`}
                                    />
                                ) : (
                                    <span>{stat?.title}</span>
                                ))}
                            </div>

                            {/* {(stat?.title && stat.title.includes('%')) ? (
                                <CountUp
                                    start={0}
                                    end={parseInt(stat?.title)}
                                    duration={10}
                                    formattingFn={(value) => `${value}%`}
                                />
                            ) : (
                                <span>{stat?.title}</span>
                            )} */}
                            {/* <CountUp
                                start={0}
                                end={parseInt(stat?.title)}
                                duration={10}
                                formattingFn={(value) => `${value}%`} // Add percent symbol formatting function
                            /> */}
                        </motion.div>
                        <p className="text-[16px] leading-[22px] sm:text-xl font-medium">
                            {removeHtml(stat?.description)}

                        </p>
                    </div>

                ))}
            </div>
        </div>
    );
};

export default Stats;
