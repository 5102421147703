import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import TextButton from './TextButton';
import { rightTransitionVariants } from './transitions/RightTransitionVariants';

// utils
import parse from 'html-react-parser';
import { getFileUrl } from '../utils/imageURL';

const Aboutpscube = ({ aboutCMS }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const controls = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			controls.start('visible');
		}
	}, [controls, inView]);

	const navigateToAbout = () => {
		window.scrollTo(0, 0);
		navigate('/about');
	};

	const description = aboutCMS?.[0]?.description;
	const parsedDesc = description ? parse(description) : '';

	return (
		<>
			{/* <div className="max-sm:mt-[20px] flex flex-col-reverse lg:flex-row gap-16 max-container padding-y-32 padding-x"> */}
			<div className=" mt-[12px] lg:mt-[12px] flex flex-col-reverse lg:flex-row gap-16 sm:flex-row-gap-0 max-container padding-y-32 padding-x">
				<div className="w-full lg:w-2/4 flex h-full overflow-hidden rounded mt-5 sm:mt-5 lg:mt-[92px]">
					<img
						className="w-full h-full object-cover"
						src={getFileUrl(aboutCMS?.[0]?.image)}
						alt="about card"
					/>
				</div>

				{/* 
				<motion.div
					ref={ref}
					animate={controls}
					initial="hidden"
					variants={rightTransitionVariants}
					// whileInView={{ x: [100, 0], opacity: [0, 1] }}
					transition={{ duration: 2, ease: 'easeInOut' }}
					// transition={{ duration: 0.5, ease: 'easeInOut' }}
					
				>
	*/}
				<div className="lg:w-2/4 flex flex-col justify-center mt-[120px]">
					<p className="top-title">About Us </p>
					<h3 className="title">{aboutCMS?.[0]?.title}</h3>
					<div className="sub-title">{parsedDesc}</div>
					{location.pathname === '/' && (
						<TextButton label="Learn more" onClick={navigateToAbout} />
					)}
				</div>
			</div>
		</>
	);
};

export default Aboutpscube;
