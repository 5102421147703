import React from 'react';
import parse from 'html-react-parser';
import tickImage from '../assets/images/tick.png';

const customHtmlParser = (htmlContent) => {
	// Function to add Tailwind classes to parsed html elements
	const addTailwindClasses = (node) => {
		if (node.type === 'tag') {
			// Add tailwind classes based on element type
			switch (node.name) {
				case 'strong':
					node.attribs = {
						...node.attribs,
						className: 'text-black title font-semibold leading-12', // Add Tailwind classes
					};
					break;
				case 'ul':
					node.attribs = {
						...node.attribs,
						className:
							'list-none flex flex-col lg:flex-row flex-wrap gap-5 pt-6 pb-12', // Add Tailwind classes for unordered list
					};
					break;
				case 'li':
					node.attribs = {
						...node.attribs,
						className: 'sub-title inline-flex items-center w-full lg:w-5/12', // Flexbox properties and padding
						style: `background-image: url(${tickImage}); 
                                background-repeat: no-repeat; 
                                background-size: 24px; 
                                background-position: top 5px; 
                                padding-left: 32px;
								display: block;
								`, // Adjust padding and image size as needed
					};
					break;
				// Add more cases as needed for other elements
				default:
					break;
			}
		}
		return node;
	};

	// Parse the Html content and add Tailwind classes
	const parsedContent = parse(htmlContent, {
		replace: addTailwindClasses,
	});
	return <div>{parsedContent}</div>;
};

export default customHtmlParser;
