import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { getFileUrl } from '../utils/imageURL';
import { removeHtml } from '../utils/removeHtml';

const Testimonials = ({ testimonials, homeCMS }) => {
	const [swiperInit, setSwiperInit] = useState(false);
	const swiperRef = useRef(null);
	const [activeIndex, setActiveIndex] = useState(0);

	const breakpoints = {
		320: {
			slidesPerView: 1,
			spaceBetween: 0,
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 10,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		1440: {
			slidesPerView: 3,
			spaceBetween: 24,
		},
	};

	const handleSlideChange = (swiper) => {
		setActiveIndex(swiper.activeIndex);
	};

	const handleBulletClick = (index) => {
		if (swiperRef.current && swiperRef.current.slideTo) {
			swiperRef.current.slideTo(index);
		}
	};

	return (
		<div className="max-container padding-x py-17 bg-white-500 mt-[60px]">
			<div className="flex flex-col items-center text-center w-full ">
				<p className="top-title">Testimonials</p>
				<h3 className="title">{homeCMS?.[7]?.title}</h3>
				<p className="sub-title md:max-w-xl">
					{removeHtml(homeCMS?.[7]?.description)}
				</p>
			</div>
			<div className="my-16">
				<Swiper
					slidesPerView={3}
					breakpoints={breakpoints}
					loop={true}
					autoplay={{
						delay: 3000,
						disableOnInteraction: false,
						pauseOnMouseEnter: true,
					}}
					pagination={{
						el: '.testimonial-pagination',
						clickable: true,
						// bulletClass: 'testimonial-bullet',
						bulletActiveClass: 'testimonial-bullet-active',
						renderBullet: (index, className) => {
							return `<span class="${className}">${index + 1}</span>`;
						},
					}}
					onInit={(swiper) => setSwiperInit(true)}
					onSlideChange={(swiper) => handleSlideChange(swiper)}
					ref={swiperRef}
				>
					{testimonials?.map((testimonial, index) => {
						const { id, name, companyName, title, description, image } =
							testimonial;
						return (
							<SwiperSlide key={id}>
								<div className="flex flex-col justify-between border  cursor-pointer rounded-xl py-11 px-9 h-[420px]">
									<div className="h-full overflow-y-scroll scrollbar-thumb">
										<span className="flex w-[56px] h-[56px]">
											<img
												src={getFileUrl(image)}
												alt="testimonialCard"
												height={56}
												width={56}
												className="flex rounded-full"
											/>
										</span>
										<h3 className="mt-8 text-xl font-semibold">{title}</h3>
										<p className="sub-title mt-4 line-clamp-5">{description}</p>
									</div>
									<div className="pt-8">
										<h4 className="text-purple-500 text-base font-semibold">
											{name}
										</h4>
										<p className="text-sm md:text-[16px] md:text-base mt-2">
											{companyName}
										</p>
									</div>
								</div>
							</SwiperSlide>
						);
					})}
				</Swiper>
				{/* Custom pagination */}
				{testimonials && (
					<div className="testimonial-pagination mt-14 swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal">
						{testimonials.map((_, index) => (
							<div
								key={index}
								className={`testimonial-bullet ${activeIndex === index ? 'testimonial-bullet-active' : ''}`}
								onClick={() => handleBulletClick(index)} // Added this line
							></div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default Testimonials;
