import { FaFacebookF } from 'react-icons/fa';
import { AiFillInstagram, AiOutlineTwitter } from 'react-icons/ai';

import team1 from '../assets/images/team1.png';

import company1 from '../assets/images/company1.png';
import company2 from '../assets/images/company2.png';
import company3 from '../assets/images/company3.png';
import company4 from '../assets/images/company4.png';
import company5 from '../assets/images/company5.png';

import testimonial from '../assets/images/testimonialCard.png';

import invIcon1 from '../assets/images/inv-icon1.png';
import invIcon2 from '../assets/images/inv-icon2.png';
import invIcon3 from '../assets/images/inv-icon3.png';

export const navLinks = [
	{ href: '/', label: 'Home' },
	{ href: '/industries', label: 'Industries' },
	{ href: '/about', label: 'About' },
	{ href: '/contact', label: 'Contact' },
];

export const statistics = [
	{ value: '24', sign: '%', label: 'Average Profit' },
	{ value: '20', sign: '+ ', label: 'Investments' },
	{ value: '2', sign: '+ ', label: 'Years of Expertise' },
	{ value: '100', sign: '%', label: 'Transparency' },
];

export const industries = [
	{
		id: 1,
		title: 'Information Technology',
		description:
			'Lorem ipsum dolor sit amet consecte turole adipiscing elit semper dalaracc lacus velolte facilisis volutpat est velitolm.',
		link: '/information-technology',
	},
	{
		id: 2,
		title: 'Food & Beverages',
		description:
			'Lorem ipsum dolor sit amet consecte turole adipiscing elit semper dalaracc lacus velolte facilisis volutpat est velitolm.',
		link: '/food&beverages',
	},
	{
		id: 3,
		title: 'LiveStock Industry',
		description:
			'Lorem ipsum dolor sit amet consecte turole adipiscing elit semper dalaracc lacus velolte facilisis volutpat est velitolm.',
		link: '/livestock-industry',
	},
];

export const team = [
	{
		name: 'Katy Perry',
		designation: 'CEO & Co-Founder',
		image: team1,
	},
	{
		name: 'Saroj Basnet',
		designation: 'CTO & Co-Founder',
		image: team1,
	},
];

export const companies = [
	{
		image: company1,
	},
	{
		image: company2,
	},
	{
		image: company3,
	},
	{
		image: company4,
	},
	{
		image: company5,
	},
	{
		image: company3,
	},
	{
		image: company2,
	},
];

export const testimonials = [
	{
		name: 'John Smith',
		designation: 'Project Manager',
		title: 'Trustworthy & results-driven',
		description:
			'Working with PS Cube was a gmae-changer for our portfolio. Their insights and commitment to excellence are unparalleled.',
		image: testimonial,
	},
	{
		name: 'Harry Styles',
		designation: 'Project Manager',
		title: 'Trustworthy & results-driven',
		description:
			'Working with PS Cube was a gmae-changer for our portfolio. Their insights and commitment to excellence are unparalleled.',
		image: testimonial,
	},
	{
		name: 'Sam Smith',
		designation: 'Project Manager',
		title: 'Trustworthy & results-driven',
		description:
			'Working with PS Cube was a gmae-changer for our portfolio. Their insights and commitment to excellence are unparalleled.',
		image: testimonial,
	},
	{
		name: 'Selena Gomez',
		designation: 'Project Manager',
		title: 'Trustworthy & results-driven',
		description:
			'Working with PS Cube was a gmae-changer for our portfolio. Their insights and commitment to excellence are unparalleled.',
		image: testimonial,
	},
];

export const benefits = [
	{
		id: 1,
		title: 'This is the first benefit of IT Sector investment.',
	},
	{
		id: 2,
		title: 'This is the first benefit of IT Sector investment.',
	},
	{
		id: 3,
		title: 'This is the first benefit of IT Sector investment.',
	},
	{
		id: 4,
		title: 'This is the first benefit of IT Sector investment.',
	},
	{
		id: 5,
		title: 'This is the first benefit of IT Sector investment.',
	},
	{
		id: 6,
		title: 'This is the first benefit of IT Sector investment.',
	},
];

export const frequentQuestions = [
	{
		title: 'How much cost for this service?',
		description:
			'Our dedicated team is committed to ensuring that your investment experience is as seamless as possible, we coordinate every step, providing guidance and assistance whenever you need it. ',
	},
	{
		title: 'How much cost for this service?',
		description:
			'Our dedicated team is committed to ensuring that your investment experience is as seamless as possible, we coordinate every step, providing guidance and assistance whenever you need it. ',
	},
	{
		title: 'How much cost for this service?',
		description:
			'Our dedicated team is committed to ensuring that your investment experience is as seamless as possible, we coordinate every step, providing guidance and assistance whenever you need it. ',
	},
];

export const investmentKeys = [
	{
		id: 1,
		title: 'Set Clear Financial Goals',
		description:
			'Start by defining your investment objectives. Your goals will shape your investment strategy',
		image: invIcon1,
	},
	{
		id: 2,
		title: 'Educate Yourself',
		description:
			'Take the time to learn about different investment options and the financial markets.',
		image: invIcon2,
	},
	{
		id: 3,
		title: 'Diversify your Portfolio',
		description:
			'Diversification is key to managing risk. Spread your investments across different asset classes, and geographic regions.',
		image: invIcon3,
	},
];

export const socialLinks = [
	{ iconClass: FaFacebookF, alt: 'facebook logo' },
	{ iconClass: AiFillInstagram, alt: 'instagram logo' },
	{ iconClass: AiOutlineTwitter, alt: 'twitter logo' },
];

export const footerLinks = [
	{
		title: 'Quick Links',
		links: [
			{ name: 'Home', link: '/' },
			{ name: 'Industries', link: '/industries' },
			{ name: 'About', link: '/about' },
			{ name: 'Contact', link: '/contact' },
		],
	},
	{
		title: 'Company',
		links: [
			{ name: 'Policy', link: '/policy' },
			// { name: 'Support', link: '/' },
			{ name: 'Terms & Conditions', link: '/terms-and-conditions' },
		],
	},
];
