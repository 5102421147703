import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import TextButton from './TextButton';
import LoadingSpinner from './LoadingSpinner';
import { leftTransitionVariants } from './transitions/LeftTransitionVariants';

// utils
import { getTeam } from '../services/home/home.services';
import { getFileUrl } from '../utils/imageURL';
import { removeHtml } from '../utils/removeHtml';

const DEFAULT_PAGE_SIZE = 4;

const Team = ({ aboutCMS }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [isVisible, setIsVisible] = useState(false);

	const controls = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			setIsVisible(true);
			controls.start('visible');
		}
	}, [controls, inView]);

	const navigateToAbout = () => {
		window.scrollTo(0, 0);
		navigate('/about');
	};

	// Queries
	const { data: team, isLoading: teamLoading } = useQuery({
		queryKey: ['our-team'],
		queryFn: () => getTeam(0, DEFAULT_PAGE_SIZE),
	});

	if (teamLoading) {
		return <LoadingSpinner />;
	}

	// Filter our team members into two groups: first two and the rest
	const firstTwoMembers = team?.slice(0, 2) || [];
	const otherMembers = team?.slice(2) || [];

	return (
		<div className="max-container padding-x pt-[64px] md:pt-[8rem] pb-16">
			{/* 
            <motion.div
                ref={ref}
                animate={isVisible ? 'visible' : 'hidden'}
                initial="hidden"
                variants={leftTransitionVariants}
                transition={{ duration: 1, ease: 'easeInOut' }}
            >
    */}{' '}
			<div>
				<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
					<div className="flex flex-col">
						<p className="top-title">Meet Our Team </p>
						<h3 className="title">{aboutCMS?.[1]?.title}</h3>
						<p className="sub-title pb-12">
							{removeHtml(aboutCMS?.[1]?.description)}
						</p>
						{location.pathname === '/' && (
							<TextButton label="Know More" onClick={navigateToAbout} />
						)}
					</div>
					<div className="grid grid-cols-2 gap-8">
						{firstTwoMembers.map((teamMember) => (
							<TeamMember key={teamMember.id} member={teamMember} />
						))}
					</div>
				</div>
				{otherMembers.length > 0 && (
					<div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-8">
						{otherMembers.map((teamMember) => (
							<TeamMember key={teamMember.id} member={teamMember} />
						))}
					</div>
				)}
			</div>
		</div>
	);
};

const TeamMember = ({ member }) => {
	const { id, name, image, designation } = member;
	return (
		<div className="flex flex-col items-center text-center">
			<div className="flex justify-center lg:justify-between aligns-center w-[150px] h-[150px] lg:w-[180px] lg:h-[180px] xl:w-[240px] xl:h-[240px] rounded-full bg-white-400">
				<img
					className="max-w-screen lg:w-full object-cover rounded-full"
					src={getFileUrl(image)}
					alt="team"
				/>
			</div>
			<p className="testimonial-title">{name}</p>
			<p className="testimonial-desc">{removeHtml(designation)}</p>
		</div>
	);
};

export default Team;
