import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import TextButton from '../components/TextButton';

//services
import { getIndustries } from '../services/industries/industries.services';

import { removeHtml } from '../utils/removeHtml';
import LoadingSpinner from '../components/LoadingSpinner';
import { getFileUrl } from '../utils/imageURL';

import imageBanner from '../assets/images/industriesBanner.png';

const DEFAULT_PAGE_SIZE = 4;

const Industries = ({ homeCMS }) => {
	const navigate = useNavigate();

	const navigateToIndustry = (slug) => {
		window.scrollTo(0, 0);
		navigate(`/industries/${slug}`);
	};

	const isMobile = window.innerWidth <= 768;

	// Queries
	const { data: industries, isPending: industriesLoading } = useQuery({
		queryKey: ['industries'],
		queryFn: () =>
			getIndustries(0, DEFAULT_PAGE_SIZE).then((data) => data.reverse()),
	});

	if (industriesLoading) {
		return <LoadingSpinner />;
	}

	return (
		<div className="h-full padding-y-30 pt:[64px]">
			<div className="flex flex-col items-center text-center padding-x mt-5">
				<p className="top-title">Industries</p>
				<h3 className="title">{homeCMS?.[6]?.title}</h3>
				<p className="sub-title w-full lg:max-w-[602px] mb-16">
					{removeHtml(homeCMS?.[6]?.description)}
				</p>
			</div>

			<div className="max-w-screen md:relative relative h-auto">
				<div
					className="max-w-screen md:flex justify-center h-full"
					// style={{ border: '2px solid red' }}
				>
					{/* Render different banner images for mobile and desktop */}
					{isMobile ? (
						<img
							src={imageBanner}
							alt="Banner"
							className="object-contain lg:object-cover w-full h-full md:h-[502px] lg:w-full lg:h-auto"
						/>
					) : (
						<img
							src={getFileUrl(homeCMS?.[6]?.image)}
							alt="Banner"
							className="object-contain lg:object-cover w-full max-h-full md:h-[502px] lg:w-full lg:h-auto"
						/>
					)}
					<div className=" absolute max-container md:absolute top-[70%] xl:-bottom-[55%] grid grid-cols-1 lg:grid-cols-3 gap-10 md:gap-8 padding-x max-sm:mx-[14px] py-8 md:px-[60px] md:py-[62px] xl:border bg-white rounded-xl lg:mb-[10px]">
						{industries.map((industry, index) => {
							const { id, title, shortDescription, slug } = industry;
							return (
								<div
									key={id}
									className={({ isActive }) =>
										`cursor-pointer group ${isActive ? 'bg-primary' : ''}`
									}
									onClick={() => {
										navigateToIndustry(slug);
									}}
								>
									<span
										className={`py-2 px-5 rounded-lg text-2xl font-semibold bg-white-400 text-purple-500 transition ease-in-out group-hover:bg-purple-500 group-hover:text-white
										`}
									>
										{index + 1}
									</span>
									<h3 className="text-[20px] leading-[36px] md:text-3xl font-semibold mt-5">
										{title}
									</h3>
									<div className="sub-title mt-1 mb-5">
										<p className="truncated-text">
											{removeHtml(shortDescription)}
										</p>
									</div>
									<TextButton
										label="Learn more"
										className="transition-transform transform group-hover:translate-x-2 group-hover:text-purple-500"
									/>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Industries;
