import axios from '../../utils/axios';

export const getIndustries = async (skip, limit) => {
	const params = new URLSearchParams();
	params.set('filterBy', 'all');
	if (limit) params.set('limit', limit);

	const response = await axios.get('/services', { params });
	return await response?.data?.data;
};

export const getIndustry = async (slug) => {
	// const params = new URLSearchParams();
	// params.append('id', id);

	const response = await axios.get(`/services/${slug}`);
	return await response?.data?.data;
};

export const getCompanies = async (skip, limit) => {
	const params = new URLSearchParams();

	params.set('filterBy', 'all');
	if (limit) params.set('limit', limit);

	const response = await axios.get('/our-client', { params });
	return await response?.data?.data;
};
