import React from 'react';
import { BsArrowRight } from 'react-icons/bs';

const Button = ({ label, onClick }) => {
	return (
		<button
			className="button-transition button-purple-hover flex justify-center items-center gap-2 bg-purple-500 text-white rounded-xl py-4 px-6 md:py-[18px] md:px-[36px]"
			onClick={onClick}
		>
			{label}
			<BsArrowRight />
		</button>
	);
};

export default Button;
