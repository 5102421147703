import React, { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import { RxHamburgerMenu } from 'react-icons/rx';
import { FaCommentDots } from 'react-icons/fa';
import { FaFacebookF } from 'react-icons/fa';
import { AiFillInstagram } from 'react-icons/ai';
import { IoIosClose } from 'react-icons/io';
import { FaLinkedinIn } from 'react-icons/fa6';

import { navLinks } from '../constants';
import { getSiteSettings } from '../services/home/home.services';
import { getFileUrl } from '../utils/imageURL';
import LoadingSpinner from './LoadingSpinner';

const Nav = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const [activeNavLink, setActiveNavLink] = useState(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const closeMenu = () => {
		setIsMenuOpen(false); // function to toggle the menu
	};

	// Disable scrolling when the menu is open
	useEffect(() => {
		if (isMenuOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}
	}, [isMenuOpen]);

	// Queries
	const { data: siteSettings, isPending: siteSettingsLoading } = useQuery({
		queryKey: ['site-settings'],
		queryFn: () => getSiteSettings(),
	});

	if (siteSettingsLoading) {
		return <LoadingSpinner />;
	}

	// check if the current location is the home page
	const isIndustriesPage = location.pathname === '/industries';

	return (
		<header className={`bg-${isIndustriesPage ? 'white' : 'white-400'}`}>
			<div className="padding-x max-container pt-6 md:pt-0">
				<div className="hidden md:flex justify-between gap-2 py-3 px-4">
					<p className="text-purple-500 text-sm leading-5">
						Empowering clients with strategic investments that drive lasting
						success.
					</p>
					<ul className="flex justify-between gap-4 items-center">
						<li className="circle-icon purple-icon-transition">
							<Link to={siteSettings?.facebook}>
								<FaFacebookF className="" />
							</Link>
						</li>
						<li className="circle-icon purple-icon-transition">
							<Link to={siteSettings?.linkedIn}>
								<AiFillInstagram />
							</Link>
						</li>
						<li className="circle-icon purple-icon-transition">
							<Link to={siteSettings?.twitter}>
								<FaLinkedinIn />
							</Link>
						</li>
					</ul>
				</div>
				<nav className="flex justify-between items-center bg-purple-500 text-white-500 px-6 py-3 lg:py-0 rounded-[24px]">
					<Link to="/" className="flex items-center space-x-3 z-10">
						{!isMenuOpen && (
							<>
								<img
									src={getFileUrl(siteSettings?.headerLogo)}
									alt="Logo"
									width={54}
									height={58}
									className="object-contain"
								/>
								<span className="text-2xl font-bold tracking-tight">
									PS CUBE
								</span>
							</>
						)}
					</Link>
					<ul className="flex flex-1 justify-center items-center max-lg:hidden">
						{navLinks.map((item) => (
							<li
								key={item.href}
								className={`p-8 
								${
									location.pathname === item.href
										? 'Link-hover'
										: 'bg-purple-500'
								} hover:button-transition hover:Link-hover active:Link-hover cursor-pointer`}
								onClick={() => {
									navigate(item.href);
									setActiveNavLink(item.href);
								}}
							>
								{item.label}
							</li>
						))}
					</ul>
					<div className="flex items-center gap-6">
						<div className="flex space-x-3 items-center max-sm:hidden">
							<div className="p-2.5 bg-black-400 rounded-[6px] cursor-pointer">
								<FaCommentDots className="h-[22px] w-[22px]" />
							</div>

							<div className="cursor-pointer">
								<p className="text-sm font-normal leading-[18px]">
									Talk to an Expert
								</p>
								<p className="text-base font-medium leading-6">
									{siteSettings?.email}
								</p>
							</div>
						</div>
						<div className="block xl:hidden z-50">
							<RxHamburgerMenu
								className="small-icon"
								onClick={() => setIsMenuOpen(!isMenuOpen)} // add an onclick handler to toggle the menu.
							/>
							{isMenuOpen && (
								<div className="absolute top-0 left-0 right-0 h-screen bg-white-400">
									<ul className="padding-x">
										<div className="flex w-full justify-between border-black mt-2.5">
											<Link to="/" className="flex items-center gap-2">
												<img
													src={getFileUrl(siteSettings?.footerLogo)}
													alt="Logo"
													width={54}
													height={58}
													className="object-contain"
												/>
												<span className="text-2xl font-bold text-[#704998] tracking-tight">
													PS CUBE
												</span>
											</Link>
											<div
												className="cursor-pointer flex items-center"
												style={{ color: '#704998' }}
												onClick={closeMenu}
											>
												<IoIosClose className="w-[40px] h-[40px]" />
											</div>
										</div>
										<div className="mt-[30px]">
											{navLinks.map((item) => (
												<li
													key={item.href}
													className="p-4 text-xl font-semibold cursor-pointer flex justify-center text-purple-400 hover:text-purple-500 transition-all"
													onClick={() => {
														navigate(item.href);
														closeMenu();
													}}
												>
													{item.label}
												</li>
											))}
										</div>
									</ul>

									<ul className="flex justify-center pt-16 gap-6 items-center padding-x">
										<li className="circle-icon purple-icon-transition">
											<Link to={siteSettings?.facebook}>
												<FaFacebookF />
											</Link>
										</li>
										<li className="circle-icon purple-icon-transition">
											<Link to={siteSettings?.linkedIn}>
												<AiFillInstagram />
											</Link>
										</li>
										<li className="circle-icon purple-icon-transition">
											<Link to={siteSettings?.twitter}>
												<FaLinkedinIn />
											</Link>
										</li>
									</ul>
								</div>
							)}
						</div>
					</div>
				</nav>
			</div>
		</header>
	);
};

export default Nav;
