import React, { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';

import { BsEnvelope } from 'react-icons/bs';
import { IoCallOutline, IoLocationOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';

import { removeHtml } from '../utils/removeHtml';
import LoadingSpinner from '../components/LoadingSpinner';

// services
import { submitContactForm } from '../services/contact/contact.services';
import {
	getCMS,
	getCMSPageTitle,
	getSiteSettings,
} from '../services/home/home.services';

const DEFAULT_PAGE_SIZE = 4;
const contact_slug = 'contact-page';
const contact_map = 'contact-map';

const Contact = () => {
	const initialUserData = {
		email: '',
		name: '',
		subject: '',
		message: '',
	};

	const [inputValue, setInputValue] = useState('');

	const [data, setData] = useState(initialUserData);

	const mutation = useMutation({
		mutationFn: submitContactForm,

		onSuccess: (data) => {
			toast.success(data);

			clearForm();
		},
		onError: (err) => {
			toast.error(err);
		},
	});

	const clearForm = () => {
		setData(initialUserData);
	};

	// Queries
	const { data: contactCMS, isPending: contactCMSLoading } = useQuery({
		queryKey: ['contact-cms', contact_slug],
		queryFn: () => getCMS(contact_slug, DEFAULT_PAGE_SIZE),
	});
	const { data: siteSettings, isPending: siteSettingsLoading } = useQuery({
		queryKey: ['site-settings'],
		queryFn: () => getSiteSettings(),
	});
	const { data: contactMap, isPending: contactMapLoading } = useQuery({
		queryKey: ['contact-map', contact_map],
		queryFn: () => getCMS(contact_map, DEFAULT_PAGE_SIZE),
	});
	const { data: pageTitle, isPending: pageTitleLoading } = useQuery({
		queryKey: ['cms-pageTitle', contact_slug],
		queryFn: () => getCMSPageTitle(contact_slug, DEFAULT_PAGE_SIZE),
	});

	if (
		contactCMSLoading ||
		siteSettingsLoading ||
		contactMapLoading ||
		pageTitleLoading
	) {
		return <LoadingSpinner />;
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		// // Call the mutation function to submit the form data
		const formData = new FormData();
		formData.append('email', data.email);
		formData.append('name', data.name);
		formData.append('subject', data.subject);
		formData.append('message', data.message);

		mutation.mutate(formData);
	};

	const handleInputChange = (e) => {
		console.log(e.target.name, e.target.value);
		const { name, value } = e.target;

		// Use regular expression to match only numeric characters
		const numericValue =
			name === 'subject' ? value.replace(/[^0-9]/g, '') : value;

		setData({
			...data,
			[name]: numericValue,
		});
	};

	return (
		<>
			<Helmet>
				<title>{`PSCUBE | ${pageTitle?.title}`}</title>
			</Helmet>
			<div className="flex flex-col gap-12 items-center pb-24 md:relative h-full">
				<div className="w-full bg-white-400 ">
					<div className="flex flex-col items-center text-center padding-x pt-[72px] pb-10 md:pb-72">
						<p className="top-title">Contact Us</p>
						<h3 className="title">{contactCMS?.[0]?.title}</h3>
						<p className="sub-title px-3 max-w-xl">
							{removeHtml(contactCMS?.[0]?.description)}
						</p>
					</div>
				</div>

				<div className="hidden md:block py-36 bg-transparent"></div>

				{/* Contact Form */}
				<div className="max-container padding-x w-full flex justify-center padding-x md:absolute md:top-[22%] max-h-max">
					<div className="flex rounded-[20px] w-full md:w-2/4 justify-center border p-5 lg:px-[54px] lg:py-[60px] mb-4 bg-white box-shadow">
						<form
							className="flex flex-col w-full gap-5 max-w-xl"
							onSubmit={handleSubmit}
						>
							<input
								className="rounded-xl appearance-none border-2 w-full py-4 px-6 text-gray-700 leading-6 outline-none focus:outline-none focus:border-purple-500"
								name="name"
								type="text"
								placeholder="Full Name"
								value={data.name}
								onChange={handleInputChange}
								required
							/>
							<input
								className="rounded-xl appearance-none border-2 w-full py-4 px-6 text-gray-700 leading-6 focus:outline-none focus:border-purple-500"
								name="email"
								type="email"
								placeholder="Email Address"
								value={data.email}
								onChange={handleInputChange}
								required
							/>
							<input
								className="rounded-xl appearance-none border-2 w-full py-4 px-6 text-gray-700 leading-6 focus:outline-none focus:border-purple-500"
								name="subject"
								type="text"
								pattern="[0-9]*"
								inputMode="numeric"
								placeholder="Contact"
								value={data.subject}
								onChange={handleInputChange}
								required
							/>
							<textarea
								className="rounded-xl resize-none appearance-none border-2 w-full py-4 px-6 text-gray-700 leading-6 focus:outline-none focus:border-purple-500 h-[136px]"
								name="message"
								type="text"
								placeholder="Please type your message here ..."
								value={data.message}
								onChange={handleInputChange}
								required
							></textarea>
							<button
								className="button-transition button-purple-hover bg-purple-500 text-white-400 leading-6 font-bold rounded-xl mt-5"
								type="submit"
								disabled={mutation.isPending}
							>
								{mutation.isPending ? 'Submitting ...' : 'Send Message'}
							</button>
							{mutation.isError && (
								<div className="error-message">
									An error occured while submitting the form.
								</div>
							)}
						</form>
					</div>
				</div>

				<div className="max-container padding-x w-full md:w-3/5 flex flex-col lg:flex-row gap-12 justify-between 2xl:justify-center border-t border-[#8D8BA7] border-opacity-[.24] pt-14">
					<div className="flex flex-col items-center">
						<span className="text-purple-500 w-[32px] h-[32px]">
							<BsEnvelope className="small-icon" />
						</span>
						<p className="sub-title pt-3">{siteSettings?.email}</p>
						<p className="sub-title">{siteSettings?.email2}</p>
					</div>
					<div className="flex flex-col items-center">
						<span className="text-purple-500 w-[32px] h-[32px]">
							<IoLocationOutline className="small-icon" />
						</span>
						<p className="sub-title pt-3 text-center w-[50%] lg:w-[80%]">
							{siteSettings?.address}
						</p>
						{/* <p className="sub-title">FORRESTDALE, WA, 6112</p> */}
					</div>
					<div className="flex flex-col items-center">
						<span className="text-purple-500 w-[32px] h-[32px]">
							<IoCallOutline className="small-icon" />
						</span>
						<p className="sub-title pt-3">
							<strong className="text-black">AU: </strong>
							{siteSettings?.phone},
						</p>
						<p className="sub-title">
							<strong className="text-black">NEP: </strong>
							{siteSettings?.phone2}
						</p>
					</div>
				</div>
			</div>

			{/* Map Section */}
			<div className="max-w-screen overflow-hidden">
				<iframe
					src={contactMap?.[0]?.title}
					width="100%"
					height="450"
					allowFullScreen=""
					style={{ border: 0 }}
					loading="lazy"
					aria-hidden="false"
					tabIndex="0"
				></iframe>
			</div>
		</>
	);
};

export default Contact;
