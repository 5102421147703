import axios from '../../utils/axios';

export const getCMS = async (slug, limit) => {
	const params = new URLSearchParams();
	params.set('filterBy', 'all');
	if (limit) params.set('limit', limit);
	const response = await axios.get(`/cms/${slug}`, { params });
	return await response?.data?.data?.childPages;
};

export const getTestimonials = async (skip, limit) => {
	const params = new URLSearchParams();
	params.set('skip', skip);
	params.set('filterBy', 'all');
	if (limit) params.set('limit', limit);
	const response = await axios.get('/testimonials', { params });
	return await response?.data?.data;
};

export const getSiteSettings = async () => {
	const response = await axios.get('/site-settings');
	return await response?.data?.data;
};

export const getTeam = async (skip, limit) => {
	const params = new URLSearchParams();
	params.set('skip', skip);
	params.set('filterBy', 'all');
	if (limit) params.set('limit', limit);
	const response = await axios.get('/our-team', { params });
	return await response?.data?.data;
};

export const getCMSPageTitle = async (slug, limit) => {
	const params = new URLSearchParams();
	params.set('filterBy', 'all');
	if (limit) params.set('limit', limit);
	const response = await axios.get(`/cms/${slug}`, { params });
	return await response?.data?.data;
};
