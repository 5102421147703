import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { motion, useAnimation } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { useInView } from 'react-intersection-observer';
import parse from 'html-react-parser';

import { Disclosure, Transition } from '@headlessui/react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

import { Company, Contact, Team, Aboutpscube } from '../components';
import LoadingSpinner from '../components/LoadingSpinner';
import { rightTransitionVariants } from '../components/transitions/RightTransitionVariants';

// services
import { getCMS, getCMSPageTitle } from '../services/home/home.services';

// utils
import { getFileUrl } from '../utils/imageURL';
import { removeHtml } from '../utils/removeHtml';

const DEFAULT_PAGE_SIZE = 4;
const about_slug = 'about-page';
const investment_slug = 'investment-page';
const question_slug = 'frequent-questions';

const AboutUs = () => {
	const controls = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			controls.start('visible');
		}
	}, [controls, inView]);

	// Queries
	const { data: aboutCMS, isPending: aboutCMSLoading } = useQuery({
		queryKey: ['about-cms', about_slug],
		queryFn: () => getCMS(about_slug, DEFAULT_PAGE_SIZE),
	});
	const { data: pageTitle, isPending: pageTitleLoading } = useQuery({
		queryKey: ['cms-pageTitle', about_slug],
		queryFn: () => getCMSPageTitle(about_slug, DEFAULT_PAGE_SIZE),
	});
	const { data: investmentCMS, isPending: investmentCMSLoading } = useQuery({
		queryKey: ['company-cms', investment_slug],
		queryFn: () => getCMS(investment_slug, 4),
	});
	const { data: questions, isPending: questionsLoading } = useQuery({
		queryKey: ['questions', question_slug],
		queryFn: () => getCMS(question_slug, 4),
	});

	if (
		aboutCMSLoading ||
		pageTitleLoading ||
		investmentCMSLoading ||
		questionsLoading
	) {
		return <LoadingSpinner />;
	}
	console.log(aboutCMS?.[0].description, 'aboutCMS');
	const description = aboutCMS?.[0]?.description;
	console.log(description, 'description');
	//const parsedDesc = description ? parse(description) : '';

	const investmentKeys = investmentCMS?.slice(1, 4);
	return (
		<>
			<Helmet>
				<title>{`PSUBE | ${pageTitle?.title}`}</title>
			</Helmet>
			<div className="bg-white-400">
				<div className="max-container padding-x pt-[72px] pb-10 text-center">
					<p className="top-title">Know More</p>
					<h3 className="title">About PS Cube</h3>
				</div>
			</div>

			<Aboutpscube aboutCMS={aboutCMS} />

			{/* <About aboutCMS={aboutCMS} /> */}

			<Team aboutCMS={aboutCMS} />

			{/* Who we Are */}

			<div
				className="flex flex-col justify-between gap-10 max-container padding-x pt-[64px] md:py-[128px]
						lg:flex-row lg:gap-0 "
			>
				<div className="flex w-full h-[480px] md:h-[648px] lg:w-2/4">
					<img
						src={getFileUrl(aboutCMS?.[2]?.image)}
						className="w-full rounded-xl h-full object-cover"
						alt="about"
					/>
				</div>
				{/*
				<motion.div
					ref={ref}
					animate={controls}
					initial="hidden"
					// initial={{ x: 100, opacity: 0 }}
					variants={rightTransitionVariants}
					// animate={{ x: 0, opacity: 1 }}
					// whileInView={{ x: [100, 0], opacity: [0, 1] }}
					transition={{ duration: 1, ease: 'easeInOut' }}
					className="flex flex-col justify-center w-full lg:w-5/12"
				>
	*/}
				<div className="flex flex-col justify-center w-full lg:w-5/12">
					<h3 className="title">{aboutCMS?.[2]?.title}</h3>
					<p className="sub-title">{parse(aboutCMS?.[2]?.description)}</p>
				</div>
			</div>

			<Company />

			{/* Financial Investments */}
			<div className="flex flex-col items-center gap-14 max-container padding-x py-[110px]">
				<div className="flex flex-col items-center max-w-screen-sm text-center">
					<p className="top-title">Steps</p>
					<h3 className="title">{investmentCMS?.[0]?.title}</h3>
					<p className="sub-title">
						{removeHtml(investmentCMS?.[0]?.description)}
					</p>
				</div>
				<div className="flex flex-col lg:flex-row gap-10 max-lg:gap:0 justify-between">
					{/* <motion.div
						whileInView={{ x: [-100, 0], opacity: [0, 1] }}
						transition={{ duration: 2, ease: 'easeInOut', delay: 0.5 }}
						className="flex w-full lg:w-7/12"
					> */}
					<div>
						<img
							src={getFileUrl(investmentCMS?.[0]?.image)}
							className="rounded-xl"
							alt="investment"
						/>
					</div>
					{/* </motion.div> */}
					{/* 
					<motion.ul
						whileInView={{ x: [100, 0], opacity: [0, 1] }}
						transition={{ duration: 2.5, ease: 'easeInOut', delay: 0.5 }}
						className="flex flex-col justify-center gap-9 w-full lg:w-5/12"
					>
				*/}
					<ul className="flex flex-col justify-center gap-9 w-full lg:w-5/12">
						{investmentKeys?.map((investmentKey) => {
							const { id, title, image, description } = investmentKey;
							return (
								<li className="flex gap-3 md:gap-8" key={id}>
									<span className="flex items-center justify-center rounded-xl bg-purple-500 w-[50px] h-[50px]">
										<img src={getFileUrl(image)} alt="investment icon" />
									</span>
									<div className="w-10/12">
										<h3 className="text-[20px] md:text-2xl font-semibold">
											{title}
										</h3>
										<p className="sub-title pt-3">{removeHtml(description)}</p>
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			</div>

			<div className="flex flex-col max-container padding-x pb-[110px]">
				{/* Accordion */}
				<h3 className="title pb-6 border-b border-white-300">
					Frequent Questions
				</h3>

				{questions?.map((item, index) => (
					<div className="border-b border-white-300 py-5" key={index}>
						<Disclosure>
							{({ open }) => (
								<>
									<Disclosure.Button className="flex items-center w-full justify-between p-0 text-lg font-medium">
										<span className="text-left">{item.title}</span>
										{open ? <AiOutlineMinus /> : <AiOutlinePlus />}
									</Disclosure.Button>
									<Transition
										show={open}
										enter="transition duration-100 ease-out"
										enterFrom="transform scale-95 opacity-0"
										enterTo="transform scale-100 opacity-100"
										leave="transition duration-75 ease-out"
										leaveFrom="transform scale-100 opacity-100"
										leaveTo="transform scale-95 opacity-0"
									>
										<Disclosure.Panel className="sub-title pt-4" static>
											{removeHtml(item.description)}
										</Disclosure.Panel>
									</Transition>
								</>
							)}
						</Disclosure>
					</div>
				))}
			</div>
			<Contact />
		</>
	);
};

export default AboutUs;
