import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { Button } from '../components';

// utils
import { removeHtml } from '../utils/removeHtml';
import { getFileUrl } from '../utils/imageURL';

const Hero = ({ homeCMS }) => {
	const navigate = useNavigate();
	// const sectionRef = useRef(null);

	const controls = useAnimation();
	const [ref, inView] = useInView();

	useEffect(() => {
		if (inView) {
			controls.start('visible');
		}
	}, [controls, inView]);

	const navigateToIndustries = () => {
		window.scrollTo(0, 0);
		navigate('/industries');
	};

	const navigateToContact = () => {
		window.scrollTo(0, 0);
		navigate('/contact');
	};

	return (
		// <div
		// 	className="flex lg:flex-row flex-col gap-[77px] sm:pt-[45px] pt-[20px] pb-[20px] padding-x max-container" style={{ overflowX: 'hidden' }}
		<div
			className="flex lg:flex-row flex-col gap-[77px] pt-[64px]  pb-[102px] padding-x max-container"
			style={{ overflowX: 'hidden' }}
		>
			{/* <motion.div
				ref={ref}
				animate={controls}
				initial="hidden"
				variants={leftTransitionVariants}
				// initial={{ opacity: 0, x: '-100vw' }} // initial position outside the viewport
				// animate={{ opacity: 1, x: 0 }} // Final position at 0 offset
				// whileInView={{ opacity: 1 }}
				transition={{ duration: 1, ease: 'easeInOut' }}
				className="w-full lg:w-2/4 md:mt-174 lg:mt-174 sm:mt-20"
			>
	*/}
			<div className="w-full lg:w-2/4 md:mt-174 sm:mt-20">
				<h1 className="heading-text w-full text-left">
					{homeCMS?.[0]?.title}{' '}
					<span className="gradient-text">Exceptional Returns</span>
				</h1>
				<p className="sub-title max-sm:pt-2 md:pt-4 lg:w-11/12">
					{removeHtml(homeCMS?.[0]?.description)}
				</p>
				<div className="flex mt-10 items-center">
					<Button label="See Industries" onClick={navigateToIndustries} />
					<p
						className="hover-underline max-sm:text-sm px-6 py-4 md:md-9 md:py-[18px] text-purple-400 cursor-pointer font-medium leading-6 "
						onClick={navigateToContact}
					>
						Talk to an Expert
					</p>
				</div>
			</div>
			{/* 
			<motion.div
				initial={{ opacity: 0, x: '100vw' }} // Initial position outside the viewport
				animate={{ opacity: 1, x: 0 }} // Final position at 0 offset
				transition={{
					duration: 3.5,
					// bounce: 0.25,
					ease: 'easeInOut',
					delay: 0.5,
				}}
				className="sm:flex-1 flex w-full lg:w-2/4"
			>
				<motion.img
					src={getFileUrl(homeCMS?.[0]?.image)}
					alt="hero background"
					className="object-cover"
					// initial={{ opacity: 0, x: '100vw' }} // Initial position outside the viewport
					// animate={{ opacity: 1, x: 0 }} // Final position at 0 offset
					// transition={{
					// 	duration: 1,
					// 	type: 'spring',
					// 	stiffness: 120,
					// 	delay: 0.5,
					// }}
				/>
			</motion.div>
			*/}
			<div className="sm:flex-1 flex w-full lg:w-2/4">
				<img
					src={getFileUrl(homeCMS?.[0]?.image)}
					alt="hero background"
					className="object-cover"
				/>
			</div>
		</div>
	);
};

export default Hero;
